import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import AboutTheDriverBaseComponent, {
  AboutTheDriverData,
} from 'components/FormPage/aboutTheDriver';
import { DriverType } from 'types/forms';

type AboutTheDriverProps = {
  data: {
    csAddDriverAboutTheDriverTemplate: AboutTheDriverData;
  };
  location: Location;
};

const AboutTheDriverPage: React.FC<AboutTheDriverProps> = ({ data, location }) => (
  <AboutTheDriverBaseComponent
    data={data.csAddDriverAboutTheDriverTemplate}
    location={location}
    driverType={DriverType.PERMANENT}
  />
);

export default AboutTheDriverPage;

export const query = graphql`
  query {
    csAddDriverAboutTheDriverTemplate(driver_type: { eq: "Permanent" }) {
      meta_title
      heading
    }
  }
`;
